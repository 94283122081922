.ItemClaimCard {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: 1s;
    font-family: 'ChakraPetch', cursive;
  }

  .Info_KeysLeft {
    font-size: 1.2rem;
  }

  .Info_Title {
    background-color: #9d00ff;
    font-weight: bold;
  }

  .Modal_Entries p {
    font-size: 0.85rem !important;
  }
  
  .Modal_Entry p {
    font-size: 0.85rem !important;
  }

  .ItemClaimCard p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  
  .ItemClaimCard span {
    color: white;
  }
  

  
  .ItemClaimCard_Separator {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5px;
    width: 100%;
  }
  
  .ItemClaimCard_ActionButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .ItemClaimCard_ActionButton:hover {
    background-color: #9d00ff;
    color: #f2f3f5;
  }
  
  @keyframes anim-glow {
    0% {
      filter: drop-shadow(0 0 2rem #9d00ff);
    }
    50% {
      filter: drop-shadow(0 0 0rem #9d00ff);
    }
    100% {
      filter: drop-shadow(0 0 2rem #9d00ff);
    }
  }
  
  .react-numeric-input input {
    padding-right: 6ex !important;
    padding-left: 6ex !important;
    min-height: 50px;
    font-family: 'ChakraPetch', cursive;
    color: white;
    background: transparent;
    outline: none;
    border: 2px solid white;
  }
  
  .react-numeric-input b {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background: transparent !important;
  }
  
  .react-numeric-input b:hover {
    cursor: pointer !important;
  }
  
  .react-numeric-input b:nth-child(3) {
    border-right: 2px solid white !important;
  }
  
  .react-numeric-input b:nth-child(2) {
    border-left: 2px solid white !important;
  }
  
  .react-numeric-input b i {
    font-family: 'ChakraPetch', cursive !important;
    background: white !important;
  }
  
  .Modal_Body {
    font-family: 'ChakraPetch', cursive;
  }
  
  .skylight-dialog {
    background: black !important;
    color: white !important;
  }
  
  .separator {
    height: 0.5px;
    background-color: white;
    width: 100%;
    margin: 0 auto;
  }
  
  .Modal_Title {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  
  .Modal_Title p {
    margin-left: 20px;
    font-size: 1.2rem;
  }
  
  .skylight-close-button {
    top: 10px !important;
    right: 20px !important;
  }
  
  .Modal_Entries {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .Modal_Entry {
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
  }
  
  .Modal_Entry a {
    margin-left: 10px;
    color: blue;
  }
  
  .Modal_Actions {
    display: flex;
    justify-content: center;
  }

  
  .Modal_Actions button {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .Modal_Confirmation {
    background: green;
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
    animation: flash 2.5s ease-in infinite;
  }
  
  .Modal_Status {
    text-align: left !important;
  }
  
  .Modal_Status span {
    color: #9d00ff;
    font-size: 0.9rem !important;
  }
  
  @media only screen and (max-width: 600px) {
    .ItemClaimCard {
      min-width: unset;
      padding: 0;
    }
  
    .Info_KeysLeft {
      font-size: 0.85rem !important;
    }
  
    .Info_Balance {
      font-size: 0.75rem !important;
    }
  
    .Info_Title {
      font-size: 0.75rem !important;
    }
  
    .AccessKey_Logo {
      height: 50px;
    }
    .Modal_Status {
      font-size: 0.8rem !important;
    }
  
    .skylight-close-button {
      font-size: 1.4rem !important;
    }
    .Modal_Status span {
      color: #9d00ff;
      font-size: 0.8rem !important;
    }
    .Modal_Entries {
      flex-direction: column;
      justify-content: center;
    }
    .Modal_Entry {
      flex-direction: column;
      font-size: 0.8rem;
      margin-top: 10px;
      margin-bottom: 10px;
      max-width: 100%;
    }
    .Modal_Entry p {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 0.4rem !important;
      word-wrap: break-word;
    }

    .Modal_Title p {
      font-size: 0.5rem !important;
    }

    .Modal_Status span {
      font-size: 0.5rem !important;
    }

    .Modal_Actions button {
      padding: 15px 20px;
    }
    
    .Modal_Actions {
      flex-direction: column;
    }
  
    .ItemClaimCard_Footer p {
      font-size: 0.75rem;
    }
  }
  
  
  @keyframes flash {
    0% {
      background: rgb(0, 150, 0);
    }
    50% {
      background: rgb(0, 195, 0);
    }
    100% {
      background: rgb(0, 150, 0);
    }
  }
  
  .PassText {
    color: white;
    max-width: 85%;
  }
  
  .PassInput {
    width: 50%;
    height: 35px;
    font-family: 'ChakraPetch', cursive;
  }
  