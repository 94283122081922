.fab-btn {
    position: fixed;
    left: 10px;
    bottom: 10px !important;
    z-index: 1000;
    right: unset !important;
    cursor: pointer;
}

.fab-btn a {
    cursor: pointer;
}

.fab-btn button {
    cursor: pointer;
}