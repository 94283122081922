@font-face {
  font-family: CaliforniaSunshineAlternate;
  src: url('./assets/fonts/california-sunshine/ttf/CaliforniaSunshineAlternate.ttf');
}

@font-face {
  font-family: ChakraPetch;
  src: url('./assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf');
}

@font-face {
  font-family: CaliforniaSunshineBasic;
  src: url('./assets/fonts/california-sunshine/ttf/CaliforniaSunshineBasic.ttf');
}

html {
  height: 100%;
}

body {
  margin: 20px;
  font-family: 'Press Start 2P', cursive, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  body {
    background: black;
  }
}

/* Creates an animation with 20 steaps. For each step, it calculates 
  a percentage for the specific step. It then generates a random clip
  box to be used for the random glitch effect. Also adds a very subtle
  skew to change the 'thickness' of the glitch.*/
@keyframes glitch-anim {
  0% {
    clip: rect(86px, 9999px, 33px, 0);
    transform: skew(0.38deg);
  }
  5% {
    clip: rect(87px, 9999px, 63px, 0);
    transform: skew(0.08deg);
  }
  10% {
    clip: rect(89px, 9999px, 26px, 0);
    transform: skew(0.5deg);
  }
  15% {
    clip: rect(32px, 9999px, 97px, 0);
    transform: skew(0.17deg);
  }
  20% {
    clip: rect(74px, 9999px, 84px, 0);
    transform: skew(0.42deg);
  }
  25% {
    clip: rect(67px, 9999px, 100px, 0);
    transform: skew(0.86deg);
  }
  30% {
    clip: rect(98px, 9999px, 78px, 0);
    transform: skew(0.41deg);
  }
  35% {
    clip: rect(61px, 9999px, 21px, 0);
    transform: skew(0.55deg);
  }
  40% {
    clip: rect(67px, 9999px, 30px, 0);
    transform: skew(0.72deg);
  }
  45% {
    clip: rect(88px, 9999px, 47px, 0);
    transform: skew(0.66deg);
  }
  50% {
    clip: rect(49px, 9999px, 58px, 0);
    transform: skew(0.54deg);
  }
  55% {
    clip: rect(4px, 9999px, 10px, 0);
    transform: skew(0.21deg);
  }
  60% {
    clip: rect(65px, 9999px, 30px, 0);
    transform: skew(0.23deg);
  }
  65% {
    clip: rect(85px, 9999px, 91px, 0);
    transform: skew(0.99deg);
  }
  70% {
    clip: rect(73px, 9999px, 40px, 0);
    transform: skew(0.56deg);
  }
  75% {
    clip: rect(44px, 9999px, 96px, 0);
    transform: skew(0.1deg);
  }
  80% {
    clip: rect(37px, 9999px, 16px, 0);
    transform: skew(1deg);
  }
  85% {
    clip: rect(17px, 9999px, 88px, 0);
    transform: skew(0.62deg);
  }
  90% {
    clip: rect(11px, 9999px, 16px, 0);
    transform: skew(0.91deg);
  }
  95% {
    clip: rect(87px, 9999px, 8px, 0);
    transform: skew(0.54deg);
  }
  100% {
    clip: rect(15px, 9999px, 89px, 0);
    transform: skew(0.47deg);
  }
}
@keyframes glitch-anim2 {
  0% {
    clip: rect(77px, 9999px, 96px, 0);
    transform: skew(0.01deg);
  }
  5% {
    clip: rect(96px, 9999px, 89px, 0);
    transform: skew(0.3deg);
  }
  10% {
    clip: rect(14px, 9999px, 6px, 0);
    transform: skew(0.34deg);
  }
  15% {
    clip: rect(45px, 9999px, 90px, 0);
    transform: skew(0.99deg);
  }
  20% {
    clip: rect(68px, 9999px, 65px, 0);
    transform: skew(0.51deg);
  }
  25% {
    clip: rect(26px, 9999px, 88px, 0);
    transform: skew(1deg);
  }
  30% {
    clip: rect(63px, 9999px, 96px, 0);
    transform: skew(0.56deg);
  }
  35% {
    clip: rect(16px, 9999px, 71px, 0);
    transform: skew(0.94deg);
  }
  40% {
    clip: rect(15px, 9999px, 2px, 0);
    transform: skew(1deg);
  }
  45% {
    clip: rect(20px, 9999px, 37px, 0);
    transform: skew(0.9deg);
  }
  50% {
    clip: rect(33px, 9999px, 47px, 0);
    transform: skew(0.32deg);
  }
  55% {
    clip: rect(95px, 9999px, 38px, 0);
    transform: skew(0.22deg);
  }
  60% {
    clip: rect(3px, 9999px, 97px, 0);
    transform: skew(0.46deg);
  }
  65% {
    clip: rect(58px, 9999px, 84px, 0);
    transform: skew(0.97deg);
  }
  70% {
    clip: rect(39px, 9999px, 94px, 0);
    transform: skew(0.68deg);
  }
  75% {
    clip: rect(69px, 9999px, 49px, 0);
    transform: skew(0.93deg);
  }
  80% {
    clip: rect(28px, 9999px, 40px, 0);
    transform: skew(0.73deg);
  }
  85% {
    clip: rect(9px, 9999px, 10px, 0);
    transform: skew(0.31deg);
  }
  90% {
    clip: rect(53px, 9999px, 51px, 0);
    transform: skew(0.51deg);
  }
  95% {
    clip: rect(23px, 9999px, 76px, 0);
    transform: skew(0.61deg);
  }
  100% {
    clip: rect(36px, 9999px, 26px, 0);
    transform: skew(0.33deg);
  }
}
